
import React, { useState, useEffect } from 'react';
// import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import CF from '../components/commonfunctions';
import closeicon from '../assets/images/ic-close-black.svg';
import dsrecommend4 from '../assets/images/DS-recommend4.png';
import deckicon from '../assets/images/ic-deck.svg';
import shareicon from '../assets/images/ic-share-black.svg';
import ReactHtmlParser from "react-html-parser";
import { propTypes } from 'react-bootstrap/esm/Image';
import { useStaticQuery, graphql } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { navigate } from "@reach/router";
import Search from '../components/search';
import Comment from '../components/comment';
import moment from "moment";
import ReadMoreAndLess from 'react-read-more-less';
import ScaleLoader from "react-spinners/ScaleLoader";
import queryString from "query-string";
import NoImage from '../assets/images/no-image-thumbnail.png';
import { share_url,s3_url } from '../config/configs'



const DSDetails = (props) => {

  const data = useStaticQuery(graphql`
 {
    WordPress {
      postBy(slug: "be-the-first-to-know") {
        title
        databaseId
        post_extra_images {
          roundImagePost {
            sourceUrl
          }
        }
        id
        categories {
          nodes {
            name
          }
        }
        story_detail {
          postPublishDate
          storyDataSection {
            addShortDescriptionBlackBackgorund
            shortDescriptionBlackBackgroundDs
            sectionTitle
            description
            image {
              sourceUrl
              altText
            }
          }
          attechDeckPdf {
            sourceUrl
          }
          relatedPosts {
            selectPost {
              ... on WordPress_Post {
                title
                slug
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                post_extra_images {
                  roundImagePost {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        comments {
          nodes {
            date
            author {
              node {
                name
              }
            }
            content
          }
        }
      }
      posts(after: "cG9zdDoxNjY=", before: "cG9zdDoxNjY=", first: 2) {
        edges {
          node {
            title
            slug
            uri
          }
        }
      },
    menu(id: "dGVybToxOQ==") {
      name
      menuItems {
        nodes {
          label
          cssClasses
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    }
    }
  }
  `)
  const [isSticky, setSticky] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [disclamier, setDisclamier] = useState(true);
  const [scrollOffset, setOffset] = useState(0);
  const [details, setDetails] = useState({})

  const [comments, setComments] = useState([])

  const handleScroll = (e) => {
    var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : "";
    setSticky(window.scrollY > headerH ? true : false);
  };
  useEffect(() => {
    let token = CF.getItem('token');
    /*  if (!token) navigate('/login'); */

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  useEffect(() => {
    if (props.location.search) {
      getDetails(props.location.search)
    }
  }, [props.location])
  // const getDetails = async (data) => {
  //   let url = queryString.parse(data)
  //   let wordpress = `https://php71.indianic.com/xumusic/wp-json/wp/v2/high_notes?slug=${url.slug}`;
  //   let response = await CF.callApi(wordpress, '', 'get', true);
  //   if (response.data && response.data[0] && response.data[0])
  //     setDetails(response.data[0])
  //   if (response.data[0]._links.replies[0].href) {
  //     let wordpress1 = response.data[0]._links.replies[0].href
  //     let response1 = await CF.callApi(wordpress1, '', 'get', true);
  //     if (response1 && response1.data && response1.data.length)
  //       setComments(response1.data)
  //   }
  // }
  const getDetails = async (data) => {

    let detailsData = await fetch(s3_url+`/high_notes.json`)
      .then(response=>{
        return response.json()
      })
      let url = queryString.parse(data)

      var res = detailsData.filter(function(val, key) {
        return val.slug === url.slug
      });
      setDetails(res[0])

    }
  const settingDisclamier = () => {
    setDisclamier(false);
    CF.setItem('disclamier', false)
  }
  const handleCategory = (e, label) => {
    if (label == "Search") {
      setIsopen(!isOpen)
    }
    else if (label == "Resources") {
      navigate('/resources')
    }
    else if (label == 'Subscribe') {
      e.preventDefault();
      e.stopPropagation();
      const scrollSec = document.querySelector(e.target.hash);
      const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
      const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
      setOffset(offsetH)
      window.scrollTo({ behavior: "smooth", top: offsetH });
    }
    else if (label === 'Show All') navigate('/showall')
    else if (label === "Category 1") navigate('/category1')
    else if (label === "Category 2") navigate('/category2')
    else if (label === "Category 3") navigate('/category3')
  }
  const pageTitle = details && details.title && details.title.rendered;
  const image = details && details.acf && details.acf.round_image_post
  const categories = details && details.categories_names;
  const postPublishDate = details && details.acf && details.acf.post_publish_date;
  const storyDataSection = details && details.acf && details.acf.story_data_section;
  const pdfSourceUrl = details && details.acf && details.acf.attech_deck_pdf;
  const relatedPosts = details && details.acf && details.acf.choose_post_to_display;
  // const comments = data.WordPress.postBy.comments && data.WordPress.postBy.comments.nodes;
  const postsSider = data.WordPress.posts && data.WordPress.posts.edges;
  let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes;
  let databaseId = details && details.id;
  let recommended_section_title = details && details.acf && details.acf.recommended_section_title
  return (
    <React.Fragment>
      {!Object.keys(data).length || (data.WordPress && data.WordPress.postBy && data.WordPress.postBy.length === 0) ?
        <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div> :
        <Layout uma="inner-page" headerColor="header-DS" title="DS" seoTitle={'UMX story details'}>
          <div className={`category-menu-outer ds-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
            <div className="container">
              {/********************* Category Menu Start *********************/}
              <nav className="category-menu">
                <ul>
                  {menuArr && menuArr.length ? menuArr.map((menu, key) => {
                    return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label)}>
                      <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#"}>
                        <span className="category-img">
                          <img src={menu && menu.menu_data && menu.menu_data.svg && menu.menu_data.svg.sourceUrl} alt={menu.label} />
                        </span>
                        <span className="category-name">{menu.label}</span>
                      </a>
                    </li>
                    )
                  }) : null}
                </ul>
              </nav>
              {/********************* Category Menu End *********************/}
            </div>
          </div>
          <Search name="ds-details" />
          <section className="common-banner ds-banner ds-details-banner">
            <div className="container">
              {/********************* Notification start *********************/}
              {(disclamier && CF.getItem('disclamier') !== 'false') ? <div className="notify">
                <p>This content is intended for internal use only and shall not be distributed outside of Universal Music Group.</p>
                <a className="close-notify" href="javascript:;" onClick={settingDisclamier}><img src={closeicon} alt="Close" /></a>
              </div> : ""}
              {/********************* Notification end *********************/}

              {/* Main title , Category , image and date start  */}
              <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="ds-main-title-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-3 text-center text-md-left">
                      <div className="ds-main-title-img">
                        <img src={image ? image : NoImage} alt="GRAMMY’S IMPACT ON STREAMS" />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="ds-main-title-content">
                        {postPublishDate ? <span className="date">{postPublishDate} </span> : ''}
                        {pageTitle ? <h3>{pageTitle} </h3> : ""}
                        <div className="category-group">
                          {categories && categories.length ? categories.map((cat, key) => {
                            return (
                              <div className="category-name" key={key}>
                                <p>{cat}</p>
                              </div>
                            )
                          }) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </section>

          {/* story section  */}
          <section className="ds-detail-wrap">

            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              {
                storyDataSection && storyDataSection.length ? storyDataSection.map((story, key) => {
                  let desc = story.description ? ReactHtmlParser(story.description) : ''
                  return (
                    <div className="ds-detail-content" key={key}>
                      <div className="container">
                        <div className="heading">
                          {/* <span className="heading-title">{(key + 1 != storyDataSection.length) ? `0${key + 1} ` : 'Conclusion'}</span> */}
                          <span className="heading-title">{story.title_with_numbersds}</span>
                          <span className="line"></span>
                        </div>
                        <h4>{story.section_title}</h4>
                        {desc}
                        {story.image && story.image.url ? <div className="ds-detail-img"> <img src={story.image && story.image.url ? story.image.url : null} alt={"data stories"} /></div> : null}

                      </div>
                      {key === 0 ? storyDataSection && storyDataSection.length ? storyDataSection.map((story, key) => {
                        return (
                          story.add_short_description_black_backgorund && story.add_short_description_black_backgorund === "yes" ?
                            <section className="ds-detail-black-block bg-black" key={key}>
                              <div className="container">
                                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                  <div className="ds-detail-black-block-content">
                                    <p>{story.short_description_black_background_ds}</p>
                                  </div>
                                </ScrollAnimation>
                              </div>
                            </section>
                            : null
                        )
                      }) : null : ""}
                    </div>
                  )
                }) : null}
            </ScrollAnimation>
            {/* Static line start */}
            <div className="any-ques">
              <div className="container">
                <p>Please reach out to <a href="#"><strong>Tatiana Fontes</strong></a> if you have any questions.</p>
              </div>
            </div>
            {/* Static line end */}


            {/* Deck and share icon start*/}
            <div className="deck-share-sec-outer">
              <div className="container">
                <div className="deck-share-sec">
                  <a href={pdfSourceUrl ? pdfSourceUrl : '#'} target="__blank" className="full-deck">
                    <span className="icon">
                      <img src={deckicon} alt="SEE FULL DECK" />
                    </span>
                    <span className="text">SEE FULL DECK</span>
                  </a>
                  <a href={"mailto:?Subject=X music&Body=" + share_url + `/blog-details?slug=${details && details.acf && details.acf.share_link}`} className="share">
                    <span className="text">SHARE</span>
                    <span className="icon">
                      <img src={shareicon} alt="SHARE" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {/* Deck and share icon end */}



          </section>
          {/* Previous Next Section Start*/}
          {
            (details && details.previous_post_title != "" || details && details.next_post_title != "") ?
              <section className="previous-next-sec" >
                <div className="container" >
                  <div className="prev-next-btn-group-wrap">
                    <div className="prev-next-btn-group-outer" onClick={() => navigate(`/blog-details/?slug=${details && details.previous_post_slug}`)}>
                      <div className="prev-next-btn-group">
                        <a href="javascript:;" className="prev">PREVIOUS POST</a>
                      </div>
                      <div className="prev-next-post-group prev-post-group">
                        <a href="javascript:;" className="prev-post" >{details && details.previous_post_title}</a>
                      </div>
                    </div>
                    <div className="prev-next-btn-group-outer text-right" onClick={() => navigate(`/blog-details/?slug=${details && details.next_post_slug}`)}>
                      <div className="prev-next-btn-group">
                        <a href="javascript:;" className="next">NEXT POST</a>
                      </div>
                      <div className="prev-next-post-group next-post-group">
                        <a href="javascript:;" className="next-post" >{details && details.next_post_title}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              : null}

          {/* Previous Next Section End*/}

          {/* Related Articles section start */}

          <section className="common-whatsnew recommend-section bg-black">
            <div className="container">
              <div className="common-whatsnew-content">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h3 className="color-white">{recommended_section_title}</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <div className="whats-new-block-outer" >
                    {relatedPosts && relatedPosts.length ? relatedPosts.map((relatePost, key) => {
                      let image = relatePost.image_for_display && relatePost.image_for_display,
                        // let image = 'https://php71.indianic.com/xumusic/wp-content/uploads/2020/06/image-32.png',
                        slug = relatePost.select_related_post_for_high_notes.post_name,
                        title = relatePost.select_related_post_for_high_notes.post_title;
                      return (
                        <div id="whatsabout" className="whats-new-block recommend-block ds-recommend-block" onClick={() => navigate(`/blog-details/?slug=${slug}`)} key={key}>
                          <div className="whats-new-img" style={{ backgroundImage: image ? `url(${image})` : `url(${dsrecommend4})` }}>
                          </div>
                          <h6>{title}</h6>
                        </div>
                      )
                    }) : null}
                    {/********************* Related Articles Start *********************/}
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </section>
          {/* Related Articles section End */}

          {
            details && details.comment_status == "open" && comments.length ?
              <>
                {/* Comment Section start */}
                <section className="comment-section">
                  <div className="container">
                    <h3 className="color-black">COMMENTS</h3>
                    <div className="comment-thread">
                      <ul className="comment-list">
                        {comments && comments.length && comments.map((each, key) => {
                          let date = each.date && moment(each.date).format("MMM Do");
                          let name = each && each.author_name ? each.author_name : "";
                          let letter = each && each.author_name ? each.author_name.charAt(0) : "";
                          let content = each.content && each.content.rendered ? (each.content.rendered).substring(3, (each.content.rendered).length - 5) : "";
                          return (<li className="comment-body" key={key}>
                            <div className={key % 2 === 0 ? "comment-body-wrap" : "comment-body-wrap comment-list-children"}>
                              <div className="comment-author">
                                <span className={key % 2 === 0 ? "comment-img" : "comment-img bg-purple-comments"}>
                                  {letter}
                                </span>
                              </div>
                              <div className="comment-content">
                                <h3 className="comment-title">{name}</h3>
                                <span className="date">{date}</span>
                                <p className="comment-desc"> <ReadMoreAndLess
                                  charLimit={60}
                                  readMoreText="Read more.."
                                  readLessText="Read less"
                                >{content}</ReadMoreAndLess></p>
                              </div>
                            </div>
                          </li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </section>
                {/* Comment Section end */}
                <Comment dataBaseId={databaseId} />
              </>
              : null
          }

        </Layout>
      }
    </React.Fragment>
  )
}
export default DSDetails
